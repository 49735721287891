import React, { useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Icon, Intent } from "@blueprintjs/core";
import type { UseQueryOptions } from "react-query";
import { Tooltip2 } from "@blueprintjs/popover2";
import MultiTabsLayout from "../../../components/MultiTabsLayout/MultiTabsLayout";
import { useGetTestExecution } from "../../../api/TestExecutionsApi";
import Error from "../../../components/Error/Error";
import DetailItem from "../../../components/DetailItem";
import Loading from "../../../components/Loading/Loading";
import ExecutionStatus from "./common/ExecutionStatus/ExecutionStatus";
import StatusCard from "../../../components/ResourceStatus/StatusCard";
import type { TestExecution } from "../../../@types/sd/testexecutions";
import { useGetFindings } from "../../../api/FindingsApi";
import type { TrafficDiffFinding } from "../../../@types/sd/findings";
// @ts-ignore
// eslint-disable-next-line import/extensions
import { DiffCat } from "../../../@types/sd/findings.d.ts";
import FlatView from "./views/FlatView";
import GroupedView from "./views/GroupedView/GroupedView";
import type { ControlProps } from "./common/Controls/Controls";
import Controls, { ViewType } from "./common/Controls/Controls";
import { filterFindings } from "./util";
import EmptyFindings from "./common/EmptyFindings/EmptyFindings";
import { getCountOfSummary } from "./common/utils";

interface TestExecutionDetailProps {
  testName?: string;
  executionName?: string;
  hideBreadcrumbs?: boolean;
  onNotFound?: () => void;
  customTitle?: (testDetailName: string) => React.ReactNode;
}

const TestExecutionDetail: React.FC<TestExecutionDetailProps> = ({
  testName: testNameProp,
  executionName: executionNameProp,
  customTitle = (t) => t,
  hideBreadcrumbs = false,
  onNotFound,
}) => {
  const [controlProps, setControlProps] = useState<ControlProps>({
    showHeaderFindings: true,
    showMetadataFindings: true,
    viewType: ViewType.Grouped,
    filterByCategories: [],
    findingsCount: {
      high: 0,
      low: 0,
      medium: 0,
    },
  });

  const {
    testName: testNameFromParams,
    executionName: executionNameFromParams,
  } = useParams<{
    testName: string;
    executionName: string;
  }>();

  const testName = testNameProp || testNameFromParams;
  const executionName = executionNameProp || executionNameFromParams;

  const navigate = useNavigate();

  const executionDetail = useGetTestExecution(testName!, executionName!);
  const findingsCall = useGetFindings(
    testName!,
    executionName!,
    0,
    undefined,
    undefined,
    {
      enabled:
        executionDetail.isSuccess &&
        executionDetail.data.status.phase === "succeeded",
    } as UseQueryOptions
  );
  const findings: TrafficDiffFinding[] = useMemo(() => {
    if (!findingsCall.data || !Array.isArray(findingsCall.data.findings))
      return [] as TrafficDiffFinding[];
    return findingsCall.data.findings.sort((a, b) => b.relevance - a.relevance);
  }, [findingsCall.data, findingsCall.status]);

  const filteredFindings = useMemo(
    () => filterFindings(findings, controlProps),
    [findings, controlProps]
  );

  const errorMessage = React.useMemo(() => {
    if (!executionDetail.data) return null;

    const status = executionDetail.data.status;
    if (status.phase !== "failed") {
      return null;
    }
    if (!status.finalState?.failed) {
      return null;
    }

    return status.finalState.failed.message;
  }, [executionDetail]);

  useEffect(() => {
    if (!executionDetail.data) return;

    const trafficDiff = executionDetail.data.results?.trafficDiff;
    if (!trafficDiff) return;

    const { red, yellow, green } = trafficDiff;

    const redCount = getCountOfSummary(red);
    const yellowCount = getCountOfSummary(yellow);
    const greenCount = getCountOfSummary(green);

    let initialSelected = DiffCat.Red;
    if (redCount === 0) {
      if (yellowCount !== 0) {
        initialSelected = DiffCat.Yellow;
      } else if (greenCount !== 0) {
        initialSelected = DiffCat.Green;
      } else {
        initialSelected = undefined;
      }
    }

    setControlProps((prev) => ({
      ...prev,
      filterByCategories: initialSelected ? [initialSelected] : [],
      findingsCount: {
        high: redCount,
        medium: yellowCount,
        low: greenCount,
      },
    }));
  }, [executionDetail.data]);

  if (executionDetail.error) {
    // TODO: Distinguish between not found error and other errors for user messaging.
    // Requires an update to have the formattedSandboxData endpoint return a 404.
    if (executionDetail.error.response?.status === 400) {
      // Response isn't set for timeouts, DNS resolution failures, server crash etc. Hence, the optional check above.
      if (onNotFound) {
        onNotFound();
      } else {
        navigate("/testing/tests");
      }
      return null;
    }
    return (
      <Error
        text={`Error: ${
          executionDetail.error.message || "Unknown error fetching the test"
        }`}
      />
    );
  }

  if (executionDetail.isLoading || !executionDetail.data) {
    return <Loading />;
  }

  const breadCrumbs = [
    {
      text: "Smart Tests",
      onClick: () => navigate("/testing/tests"),
    },
    {
      text: `Smart Test: ${testName}`,
      onClick: () => navigate(`/testing/tests/${testName}`),
    },
    {
      text: `Execution: ${executionName}`,
    },
  ];

  if (!executionDetail.data) {
    return null;
  }
  const executionData = executionDetail.data as TestExecution;
  const triggeredBy = executionData.status.triggeredBy;

  return (
    <MultiTabsLayout
      tabs={[]}
      breadcrumbs={hideBreadcrumbs ? undefined : breadCrumbs}
      title={customTitle(executionDetail.data?.name || "")}
      status={<ExecutionStatus status={executionData.status.phase} />}
    >
      {errorMessage && (
        <div className="mb-5">
          <StatusCard
            title="Job Failed"
            description={errorMessage}
            intent={Intent.DANGER}
          />
        </div>
      )}
      <div className="mb-1">
        <DetailItem
          title="Test"
          value={
            executionData.status.testDeletedAt ? (
              <Tooltip2 content="The test has been deleted">
                <>
                  {executionDetail.data.name}{" "}
                  <Icon icon="warning-sign" size={15} intent={Intent.WARNING} />
                </>
              </Tooltip2>
            ) : (
              <Link to={`/testing/tests/${executionData.spec.test}/`}>
                {executionData.spec.test}
              </Link>
            )
          }
        />
      </div>
      {triggeredBy && (
        <div className="mb-2">
          <b>Triggered on</b>{" "}
          <Link to={`/sandbox/name/${triggeredBy.sandbox}/`}>
            {triggeredBy.sandbox}
          </Link>{" "}
          containing fork of {triggeredBy.trigger.sandboxOf.namespace}/
          {triggeredBy.trigger.sandboxOf.name}
        </div>
      )}
      <div className="mb-4">
        <span className="font-bold mb-0">Jobs</span>
        <ul className="mt-0 py-0">
          <li>
            <Link
              to={`/testing/jobs/${executionDetail.data.status.baselineJob}`}
            >
              {executionDetail.data.status.baselineJob}
            </Link>{" "}
            (baseline)
          </li>
          <li>
            <Link to={`/testing/jobs/${executionDetail.data.status.targetJob}`}>
              {executionDetail.data.status.targetJob}
            </Link>{" "}
            (sandbox)
          </li>
        </ul>
      </div>
      {(findingsCall.error || !findingsCall.isFetched) &&
        "No findings available"}

      {findingsCall.isSuccess && (
        <>
          <Controls
            initialValue={controlProps}
            onChange={(cp) => setControlProps(cp)}
          />

          {filteredFindings.length === 0 && <EmptyFindings />}

          {controlProps.viewType === ViewType.Flat ? (
            <FlatView findings={filteredFindings} />
          ) : (
            <GroupedView findings={filteredFindings} testName={testName!} />
          )}
        </>
      )}
    </MultiTabsLayout>
  );
};

export default TestExecutionDetail;
