import React, { useEffect, useState } from "react";
import { Icon, Intent, OverlayToaster } from "@blueprintjs/core";
import _ from "lodash";
import yamlUtil from "js-yaml";
import styles from "./ViewSpec.module.css";
import SdButton from "../SdButton";
import SpecEditor from "../../../pages/CreateSandbox/Editor";
import BaseEditor from "../../Editor";
import {
  IMMUTABLE_SANDBOX_PATHS,
  IMMUTABLE_RUNNER_GROUPS_PATHS,
  IMMUTABLE_RESOURCE_PLUGIN_PATHS,
} from "./models";

const toaster = OverlayToaster.create();

type ResourceKind = "sandbox" | "job-runner-group" | "resource-plugin";

const getImmutablePathByResource = (kind: ResourceKind) => {
  switch (kind) {
    case "sandbox":
      return IMMUTABLE_SANDBOX_PATHS;
    case "job-runner-group":
      return IMMUTABLE_RUNNER_GROUPS_PATHS;
    case "resource-plugin":
      return IMMUTABLE_RESOURCE_PLUGIN_PATHS;
    default:
      return [];
  }
};

// Returns the first path that is different between the target and the yaml
const getFirstImmutablePathDifference = (
  rawTarget: string,
  rawSource: string,
  paths: string[]
): string | null => {
  const target = yamlUtil.load(rawTarget);
  const source = yamlUtil.load(rawSource);

  return (
    paths.find((path) => _.get(target, path) !== _.get(source, path)) ?? null
  );
};

type ViewSpecProps = {
  yaml: string;
  isEditable?: boolean;
} & (
  | {
      isEditable: true;
      onChange: (newData: string) => void;
      loading?: boolean;
      resource: ResourceKind;
    }
  | { isEditable?: false }
);

const ViewSpec: React.FC<ViewSpecProps> = ({
  yaml,
  isEditable = false,
  ...props
}) => {
  const [data, setData] = useState<string | undefined>(yaml);

  const onChange =
    isEditable && "onChange" in props ? props.onChange : undefined;
  const loading = isEditable && "loading" in props ? props.loading : false;
  const resource = isEditable && "resource" in props ? props.resource : false;

  const handleSave = (newData: string | undefined) => {
    if (!newData || !resource) {
      return;
    }

    const difference = getFirstImmutablePathDifference(
      newData,
      yaml,
      getImmutablePathByResource(resource)
    );

    // User try to update a immutable field
    if (difference) {
      toaster.show({
        message: `Field "${difference}" is immutable`,
        intent: Intent.DANGER,
      });
    } else {
      onChange?.(newData);
    }
  };

  useEffect(() => {
    setData(yaml);
  }, [yaml]);

  if (!isEditable) {
    return (
      <div className={styles.container}>
        <div className={styles.editor}>
          <BaseEditor
            language="yaml"
            value={data}
            options={{
              readOnly: true,
            }}
          />
        </div>
      </div>
    );
  }

  const handleSaveShortcut = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if ((event.ctrlKey || event.metaKey) && event.key === "s") {
      event.preventDefault();
      handleSave(data);
    }
  };

  return (
    <div className={styles.container} onKeyDown={handleSaveShortcut}>
      <SpecEditor
        text={data}
        onChange={setData}
        header={
          <div className="flex gap-4 items-center">
            <div className={styles.unsave_message}>
              {yaml !== data ? (
                <>
                  {!loading && (
                    <>
                      <Icon icon="warning-sign" intent={Intent.WARNING} />
                      <p>Changes not applied (Ctrl+S to Apply)</p>
                    </>
                  )}
                  <SdButton onClick={() => handleSave(data)} loading={loading}>
                    Apply
                  </SdButton>
                </>
              ) : (
                <div className={styles.info_text}>
                  <Icon icon="edit" intent={Intent.PRIMARY} />
                  <p>Editable spec</p>
                </div>
              )}
            </div>
          </div>
        }
      />
    </div>
  );
};

export default ViewSpec;
