import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Boundary, Breadcrumbs, Icon } from "@blueprintjs/core";
import createSandboxTemplate from "./specs/basic-sandbox";
import Editor from "./Editor";
import CreateSandboxAction from "./CreateSandboxAction";
import styles from "./CreateSandbox.module.css";
import type { SandboxV2 } from "../../@types/sd/sandboxv2";
import style from "../SandboxDetails/SandboxDetails.module.css";
import SdTheme from "../../styles/theme";
import Banner from "../../components/theming/banner/Banner";
import { DoneCheckmarkIcon } from "../../components/theming/icons";
import ActionLink from "../../components/theming/ActionLink";
import {
  CreateSandboxPageOpenQuickstart,
  CreateSandboxPageViewSandboxDetail,
  OPEN_IN_NEW_TAB,
} from "../../Constants";
import HistoryLink from "../../components/HistoryLink";

const CreateSandboxBreadcrumbs = () => {
  const navigate = useNavigate();
  return (
    <div className={style.breadcrumbs}>
      <Breadcrumbs
        collapseFrom={Boundary.START}
        items={[
          {
            icon: <Icon icon="layers" color={SdTheme.Link.lightBackground} />,
            text: (
              <span style={{ color: SdTheme.Link.lightBackground }}>
                Sandboxes
              </span>
            ),
            onClick: () => navigate(`/sandboxes`),
          },
          {
            text: (
              <span style={{ color: SdTheme.Text.lightBackground }}>
                Create
              </span>
            ),
          },
        ]}
      />
    </div>
  );
};

const Tip = () => (
  <p className={styles.tip}>
    Need help creating sandboxes? Check out the{" "}
    <ActionLink
      onClick={() => {
        window?.open(
          "https://www.signadot.com/docs/tutorials/quickstart/first-sandbox",
          OPEN_IN_NEW_TAB
        );
      }}
      eventName={CreateSandboxPageOpenQuickstart}
    >
      Quickstart Guide
    </ActionLink>
    .
  </p>
);

// TODO:
// CreateSandboxUI makes use of several components which depend on each other for data and uses events for
// passing changes to sandbox spec, API response etc. It's already a bit clunky with just a few components,
// and is bound to get clunkier with addition of every new component. Consider using a centralized state
// and make use of observer(?) design pattern.
const CreateSandbox = () => {
  const decode = (encodedData?: string) => {
    if (!encodedData) {
      return "";
    }
    let data = "";
    if (encodedData) {
      const d = decodeURIComponent(encodedData);
      data = window.atob(d);
    }
    return data;
  };

  const [searchParams] = useSearchParams();
  const encodedData = searchParams.get("encodedData");
  const [spec, setSpec] = React.useState<string>(
    encodedData ? decode(encodedData) : createSandboxTemplate
  );
  const [sandbox, setSandbox] = React.useState<SandboxV2 | undefined>();

  return (
    <div>
      <p className={styles.title}>Sandboxes</p>
      <CreateSandboxBreadcrumbs />
      <Tip />
      {sandbox ? (
        <Banner icon={<DoneCheckmarkIcon />} title="Sandbox Applied">
          Sandbox{" "}
          <HistoryLink
            url={`/sandbox/name/${sandbox.name}`}
            className={styles.link}
            eventName={CreateSandboxPageViewSandboxDetail}
          >
            {sandbox.name}
          </HistoryLink>{" "}
          applied!
        </Banner>
      ) : null}
      <Editor text={spec} onChange={setSpec} />
      <CreateSandboxAction spec={spec} onSandboxCreated={setSandbox} />
    </div>
  );
};

export default CreateSandbox;
