import type { Monaco } from "@monaco-editor/react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { editor } from "monaco-editor";
import pythonImpl from "./python";
import logsImpl from "./logs";
import IStandaloneCodeEditor = editor.IStandaloneCodeEditor;
import { BASE_DARK_THEME, SIGNADOT_DARK_THEME } from "../constants";

export interface ILanguageDefinition {
  beforeMount?(monaco: Monaco): void;
  onMount?(e: IStandaloneCodeEditor): void;
  onMonacoAvailable?(monaco: Monaco): void;
}

export const loadLanguageImplementations = (): ILanguageDefinition => {
  const impls: Array<ILanguageDefinition> = [pythonImpl, logsImpl];

  return {
    beforeMount(monaco: Monaco) {
      monaco.editor.defineTheme(SIGNADOT_DARK_THEME, {
        base: BASE_DARK_THEME,
        inherit: true,
        rules: [],
        colors: {},
      });
      impls.forEach((imp) => imp.beforeMount?.(monaco));
    },
    onMount(e: IStandaloneCodeEditor) {
      impls.forEach((imp) => imp.onMount?.(e));
    },
    onMonacoAvailable(monaco: Monaco) {
      impls.forEach((imp) => imp.onMonacoAvailable?.(monaco));
    },
  };
};
