import React, { useEffect, useMemo, useRef, useState } from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import LogsHeader from "./LogsHeader";
import styles from "./Logs.module.css";
import LogEntry from "./LogEntry";

export interface LogItem {
  message: string;
  time: string;
  type?: "error" | "output";
}

export interface LogResponseItem {
  message: string;
  cursor: string;
}

interface Props {
  header?: React.ReactNode;
  logs?: LogItem[];
  follow?: boolean;
  logPanelOpen: boolean;
}

const Logs: React.FunctionComponent<Props> = ({
  header,
  logs = [],
  follow: followInput = true,
  logPanelOpen,
}) => {
  // const [filteredLogs, setFilteredLogs] = useState<LogItem[] | undefined>(logs);
  const [searchTerms, setSearchTerms] = useState<string[]>([]);
  const [follow, setFollow] = useState(followInput);

  const parentRef = useRef<HTMLDivElement>(null);

  const filteredLogs = useMemo(
    () =>
      logs.filter((log) => {
        if (searchTerms.length === 0) return true;

        const message = log.message.toLowerCase();
        return searchTerms.some((key) => message.includes(key));
      }),
    [logs, searchTerms]
  );

  const rowVirtualizer = useVirtualizer({
    count: filteredLogs.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 20,
    enabled: true,
  });

  const items = rowVirtualizer.getVirtualItems();

  useEffect(() => {
    if (follow && logPanelOpen && filteredLogs && filteredLogs.length > 0) {
      rowVirtualizer.scrollToIndex(filteredLogs.length);
    }
  }, [filteredLogs, follow, logPanelOpen]);

  if (!filteredLogs || !Array.isArray(filteredLogs)) return null;

  return (
    <div className={styles.container}>
      <LogsHeader
        matchCount={Array.isArray(filteredLogs) ? filteredLogs.length : 0}
        setSearchTerms={setSearchTerms}
        follow={follow}
        setFollow={setFollow}
        innerContent={header}
      />

      <div
        ref={parentRef}
        style={{
          height: "52vh",
          width: "100%",
          overflowY: "auto",
          contain: "strict",
        }}
      >
        <div
          style={{
            height: rowVirtualizer.getTotalSize(),
            width: "100%",
            position: "relative",
          }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              transform: `translateY(${items[0]?.start ?? 0}px)`,
            }}
          >
            {items.map((virtualRow) => (
              <div
                key={virtualRow.key}
                data-index={virtualRow.index}
                ref={rowVirtualizer.measureElement}
              >
                <LogEntry
                  message={
                    (filteredLogs && filteredLogs[virtualRow.index].message) ??
                    ""
                  }
                  searchTerms={searchTerms}
                  time={
                    (filteredLogs && filteredLogs[virtualRow.index].time) ?? ""
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Logs;
