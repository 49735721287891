/* Required to remove warning for div with markdown in it using the dangerous API. We use a
 * sanitization lib to keep the rendered HTML clean so we don't need to worry about the
 * warning.
 */
/* eslint-disable react/no-danger */
import * as React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { GiPlug } from "react-icons/gi";
import { Alert, Intent, OverlayToaster } from "@blueprintjs/core";
import _ from "lodash";
import Loading from "../../../components/Loading/Loading";

import {
  useDeleteResourcePlugin,
  useResourcePlugin,
} from "../../../api/ResourcePluginsApi";
import Error from "../../../components/Error/Error";
import MultiTabsLayout from "../../../components/MultiTabsLayout/MultiTabsLayout";
import {
  ResourcePluginDetailTabOverview,
  ResourcePluginDetailTabSpecification,
} from "../../../Constants";
import ResourcePluginSpec from "./tabs/ResourcePluginSpec/ResourcePluginSpec";
import ResourcePluginOverview from "./tabs/ResourcePluginOverview/ResourcePluginOverview";
import useDisclosure from "../../../hooks/UseDisclosure";

const toaster = OverlayToaster.create();

interface Params extends Record<string, string> {
  name: string;
}

export interface FormattedResourceInfo {
  name: string;
  sandbox: string;
  cluster: string;
}

const ResourcePluginDetails: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { name } = useParams<Params>();
  const pluginInfo = useResourcePlugin(name!);
  const alertState = useDisclosure(false);
  const deleteResourcePluginApi = useDeleteResourcePlugin();

  if (pluginInfo.isLoading) {
    return <Loading />;
  }
  if (pluginInfo.error) {
    return <Error text={pluginInfo.error.message} />;
  }
  const plugin = pluginInfo.data!;

  const tabs = [
    {
      label: "Overview",
      slug: "overview",
      eventName: ResourcePluginDetailTabOverview,
      content: <ResourcePluginOverview resourcePlugin={plugin} />,
    },
    {
      label: "Specification",
      slug: "specification",
      eventName: ResourcePluginDetailTabSpecification,
      content: <ResourcePluginSpec resourcePlugin={plugin} />,
    },
  ];

  const handleDeleteResourcePlugin = () => {
    deleteResourcePluginApi.mutate({
      url: `/api/v2/orgs/:orgName/resource-plugins/${name}`,
    });
    alertState.close();
  };

  if (deleteResourcePluginApi.error) {
    toaster.show({
      message: _.get(deleteResourcePluginApi.error, "response.data.error"),
      intent: Intent.DANGER,
    });
    deleteResourcePluginApi.reset();
  } else if (deleteResourcePluginApi.isSuccess) {
    toaster.show({
      message: "Resource Plugin deleted",
      intent: Intent.SUCCESS,
    });
    deleteResourcePluginApi.reset();
    navigate("/resource-plugins");
  }

  return (
    <>
      <Alert
        cancelButtonText="Cancel"
        confirmButtonText="Delete Resource Plugin"
        icon="trash"
        intent={Intent.DANGER}
        isOpen={alertState.isOpen}
        onCancel={alertState.close}
        onConfirm={handleDeleteResourcePlugin}
      >
        <div>
          <p>
            Are you sure you want to delete <b>{name}</b>
          </p>
        </div>
      </Alert>
      <MultiTabsLayout
        tabs={tabs}
        title={plugin.name}
        breadcrumbs={[
          {
            // icon: <GiPlug className="mr-1" />,
            text: "Resource Plugins",
            onClick: () => navigate(`/resource-plugins`),
          },
          {
            text: plugin.name,
          },
        ]}
        actions={[
          {
            label: "Delete",
            onClick: alertState.open,
            display: true,
            isRiskAction: true,
            icon: "trash",
          },
        ]}
      />
    </>
  );
};

export default ResourcePluginDetails;
