import type { Cluster } from "../../@types/sd/cluster";

// defaultBeforeV191 refers to only apply if the operator version is lower  than 0.19.1
export type HeaderKind = "always" | "defaultBeforeV191" | "extra";
export const BASE_HEADERS: Record<string, HeaderKind> = {
  baggage: "always",
  "ot-baggage-sd-routing-key": "defaultBeforeV191",
  "ot-baggage-sd-sandbox": "defaultBeforeV191",
  tracestate: "always",
  "uberctx-sd-routing-key": "defaultBeforeV191",
  "uberctx-sd-sandbox": "defaultBeforeV191",
};

export const getHeaders = (
  cluster: Cluster | undefined
): { extra: string[]; always: string[] } => {
  // Only show the always plus the extra if defined
  const extraHeaders = cluster?.clusterConfig?.routing?.customHeaders;
  const alwaysHeaders = Object.fromEntries(
    Object.entries(BASE_HEADERS).filter(([_, v]) => v === "always")
  );

  return {
    always: Object.entries(alwaysHeaders).map(([k]) => k),
    extra: extraHeaders ?? [],
  };
};
