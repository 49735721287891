import type { Dispatch, SetStateAction } from "react";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import styles from "./Editor.module.css";
import TemplateParams from "./TemplateParams";
import BaseEditor from "../../../components/Editor";
import { findPlaceholders } from "./TemplateParams/TemplateParams";

interface EditorProps {
  text?: string;
  onChange: Dispatch<SetStateAction<string | undefined>>;
  header?: React.ReactNode;
}

type File = {
  path: string;
  language: string;
  value: string | undefined;
};

const Editor: React.FC<EditorProps> = ({ text, onChange, header }) => {
  const [values, setValues] = React.useState<Record<string, string>>({});
  const [files, setFiles] = useState<Record<"template" | "final-spec", File>>({
    template: {
      path: "template.yaml",
      language: "yaml",
      value: text,
    },
    "final-spec": {
      path: "final-spec.yaml",
      language: "yaml",
      value: "",
    },
  });
  const [selectedFile, setSelectedFile] = useState<"template" | "final-spec">(
    "template"
  );

  const [pendingFileChange, setPendingFileChange] = useState<
    string | undefined
  >(undefined);

  // This could not be added since will cause the placeholder to be replaced with the text
  // useEffect(() => {
  //   setFiles((prevFiles) => ({
  //     template: { ...prevFiles.template, value: text ?? "" },
  //     "final-spec": { ...prevFiles["final-spec"], value: text ?? "" },
  //   }));
  // }, [text]);

  useEffect(() => {
    let temp = files.template.value;
    Object.keys(values).forEach((key) => {
      const value = values[key];
      if (value) {
        // Replace the placeholder with value, along with surrounding single/double quotes if exists.
        // TODO: This replaces the matching strings in the YAML comments as well which isn't great. Address this later.
        const replacePattern = `("@{${key}}")|('@{${key}}')|(@{${key}})`;
        temp = temp?.replace(new RegExp(replacePattern, "g"), value);
      }
    });

    setFiles((prevFiles) => ({
      template: { ...prevFiles.template },
      "final-spec": {
        ...prevFiles["final-spec"],
        value: temp,
      },
    }));
  }, [files["final-spec"].value, values]);

  useEffect(() => {
    onChange(files["final-spec"].value);
  }, [files["final-spec"].value]);

  const handleValueChange = (val: string | undefined) => {
    if (selectedFile === "template") {
      setFiles((prevFiles) => ({
        template: {
          ...prevFiles.template,
          value: val ?? "",
        },
        "final-spec": {
          ...prevFiles["final-spec"],
          value: val ?? "",
        },
      }));
    }
  };

  useEffect(() => {
    // This is required, otherwise the value will not get the latest
    // selected file, causing a lost on the templates
    if (pendingFileChange) {
      handleValueChange(pendingFileChange);
      setPendingFileChange(undefined);
    }
  }, [selectedFile, pendingFileChange]);

  return (
    <div>
      <div className={styles.wrapper}>
        <div
          className={classNames(styles.editor, {
            [styles.full_editor]:
              findPlaceholders(files.template.value).size === 0,
          })}
        >
          <BaseEditor
            path={files[selectedFile].path}
            defaultLanguage={files[selectedFile].language}
            defaultValue={files[selectedFile].value}
            value={files[selectedFile].value}
            onChange={(val) => {
              setPendingFileChange(val);
            }}
            options={{
              readOnly: selectedFile === "final-spec",
            }}
            header={header}
          />
        </div>
        <TemplateParams
          template={files.template.value}
          onValueUpdate={setValues}
          onDisplayFinalSpecChange={() =>
            setSelectedFile((f) =>
              f === "template" ? "final-spec" : "template"
            )
          }
        />
      </div>
    </div>
  );
};

export default Editor;
