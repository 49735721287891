import type { Dispatch, SetStateAction } from "react";

export interface ViewSpecProps {
  title: string;
  spec: string;
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  showOpenInEditor: boolean;
}

export const IMMUTABLE_SANDBOX_PATHS = [
  "name",
  "spec.description",
  "spec.cluster",
  "spec.resources",
];
export const IMMUTABLE_RUNNER_GROUPS_PATHS = [
  "name",
  "spec.description",
  "spec.cluster",
];
export const IMMUTABLE_RESOURCE_PLUGIN_PATHS = [];
