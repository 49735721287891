import useApi, { type ApiError } from "../hooks/UseApi";
import type { ResourcePlugin } from "../@types/sd/resourceplugin";
import useApiMutation, {
  type ApiMutationResult,
} from "../hooks/UseApiMutation";
import type { RunnerGroup } from "../@types/sd/runnergroup";
import {
  APPLY_RUNNER_GROUP_API,
  GET_RUNNER_GROUP_API,
  RUNNER_GROUPS_API,
} from "./RunnerGroupApi";

const standardQueryConfig = {
  retry: 1,
  refetchInterval: 30000,
};

export const LIST_RESOURCE_PLUGINS_API_NAME = "api:resource_plugins";
export const GET_RESOURCE_PLUGIN_API_NAME = "api:get_resource_plugin";
export const DELETE_RESOURCE_PLUGIN_API_NAME = "api:delete_resource_plugin";
export const APPLY_RESOURCE_PLUGIN_API_NAME = "api:apply_resource_plugin";

export const useResourcePlugins = (options = {}) =>
  useApi<ResourcePlugin[]>(
    LIST_RESOURCE_PLUGINS_API_NAME,
    `/api/v2/orgs/:orgName/resource-plugins`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

export const useResourcePlugin = (name: string, options = {}) =>
  useApi<ResourcePlugin>(
    GET_RESOURCE_PLUGIN_API_NAME,
    `/api/v2/orgs/:orgName/resource-plugins/${name}`,
    {
      ...standardQueryConfig,
      ...options,
    }
  );

type ApplyResourcePluginApiReturn = [
  (spec: ResourcePlugin) => void,
  ApiMutationResult<ResourcePlugin, ResourcePlugin>
];

export const useApplyResourcePluginApi = (
  onSuccess: (data: ResourcePlugin) => void,
  onError: (err: ApiError) => void
): ApplyResourcePluginApiReturn => {
  const mutation = useApiMutation<ResourcePlugin, ResourcePlugin>(
    APPLY_RESOURCE_PLUGIN_API_NAME,
    "PUT",
    [GET_RESOURCE_PLUGIN_API_NAME],
    onSuccess,
    onError
  );
  return [
    (resourcePlugin: ResourcePlugin) => {
      mutation.mutate({
        data: resourcePlugin,
        url: `/api/v2/orgs/:orgName/resource-plugins/${resourcePlugin.name}`,
      });
    },
    mutation,
  ];
};
export const useDeleteResourcePlugin = () =>
  useApiMutation(DELETE_RESOURCE_PLUGIN_API_NAME, "DELETE", [
    LIST_RESOURCE_PLUGINS_API_NAME,
    GET_RESOURCE_PLUGIN_API_NAME,
  ]);
