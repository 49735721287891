import type { Dispatch, SetStateAction } from "react";
import React from "react";
import yaml from "js-yaml";
import { Intent, OverlayToaster } from "@blueprintjs/core";
import styles from "./CreateSandboxAction.module.css";
import SdButton from "../../../components/theming/SdButton";
import { useCreateSandboxApi } from "../../../api/SandboxesApi";
import type { SandboxV2 } from "../../../@types/sd/sandboxv2";
import { placeholderRegex } from "../Constants";
import { CreateSandboxPageApplySandbox } from "../../../Constants";

interface CreateSandboxActionProps {
  spec?: string;
  onSandboxCreated: Dispatch<SetStateAction<SandboxV2 | undefined>>;
}

const toaster = OverlayToaster.create();

const verifyNoPlaceholders = (text: string) => {
  const matches = placeholderRegex.exec(text);
  return matches == null;
};

export const useSaveSandboxSpec = (
  isNewSandbox: boolean,
  onCreated: (sandbox: SandboxV2 | undefined) => void
) => {
  const [createSandbox, createSandboxApi] = useCreateSandboxApi();
  const handleCreateSandbox = (spec: string) => {
    if (verifyNoPlaceholders(spec!)) {
      try {
        const req = yaml.load(spec!) as SandboxV2;
        createSandbox(req);
      } catch (e) {
        toaster.show({
          message: `error parsing yaml`,
          intent: Intent.DANGER,
        });
      }
    } else {
      toaster.show({
        message: "spec has parameters without values",
        intent: Intent.DANGER,
      });
    }
  };

  if (createSandboxApi.error) {
    toaster.show({
      message: createSandboxApi.error.response.data.error,
      intent: Intent.DANGER,
    });
    createSandboxApi.reset();
  } else if (createSandboxApi.isSuccess) {
    toaster.show({
      message: isNewSandbox ? "Sandbox created!" : "Sandbox updated!",
      intent: Intent.SUCCESS,
    });
    createSandboxApi.reset();
    onCreated(createSandboxApi.data?.data);
  }

  return {
    trigger: (spec: string) => {
      handleCreateSandbox(spec);
    },
    isLoading: createSandboxApi.isLoading,
  };
};

const CreateSandboxAction: React.FC<CreateSandboxActionProps> = ({
  spec,
  onSandboxCreated,
}) => {
  const { trigger, isLoading } = useSaveSandboxSpec(true, onSandboxCreated);

  return (
    <div className={styles.container}>
      <SdButton
        eventName={CreateSandboxPageApplySandbox}
        onClick={() => spec && trigger(spec)}
        disabled={!spec || isLoading}
      >
        Apply Sandbox
      </SdButton>
    </div>
  );
};

export default CreateSandboxAction;
