import type { Dispatch, SetStateAction } from "react";
import React from "react";
import { Switch } from "@blueprintjs/core";
import styles from "./TemplateParams.module.css";
import { placeholderRegex } from "../../Constants";

interface TemplateParamsProps {
  template?: string;
  onValueUpdate: (record: Record<string, string>) => void;
  onDisplayFinalSpecChange: Dispatch<SetStateAction<boolean>>;
}

export const findPlaceholders = (template = "") => {
  const matches = new Set<string>();
  let match = placeholderRegex.exec(template);
  while (match != null) {
    matches.add(match[1]);
    match = placeholderRegex.exec(template);
  }
  return matches;
};

const TemplateParams: React.FC<TemplateParamsProps> = ({
  template = "",
  onValueUpdate,
  onDisplayFinalSpecChange,
}) => {
  const placeholders = React.useMemo(
    () => findPlaceholders(template),
    [template]
  );
  const [displayFinalSpec, setDisplayFinalSpec] =
    React.useState<boolean>(false);
  const [valueMap, setValueMap] = React.useState<Record<string, string>>({});
  const updateValueMap = (key: string, value: string) => {
    setValueMap({ ...valueMap, ...{ [key]: value } });
  };
  React.useEffect(() => {
    onValueUpdate(valueMap);
  }, [valueMap]);
  const isFirstRender = React.useRef(true);

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    onDisplayFinalSpecChange(displayFinalSpec);
  }, [displayFinalSpec]);
  if (placeholders.size === 0) {
    onValueUpdate(valueMap);
    return null;
  }
  return (
    <div className={styles.container}>
      <p className={styles.title}>Template parameters</p>
      <ul className={styles.list}>
        {Array.from(placeholders).map((item) => (
          <li key={`placeholder-${item}`}>
            <label htmlFor={item}>{item}</label>
            <input
              type="text"
              id={item}
              onChange={(e) => updateValueMap(item, e.target.value)}
            />
          </li>
        ))}
      </ul>
      <Switch
        checked={displayFinalSpec}
        label="View final spec"
        onChange={() => setDisplayFinalSpec((prev) => !prev)}
      />
    </div>
  );
};

export default TemplateParams;
