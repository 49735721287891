import yaml from "js-yaml";
import React from "react";
import type { ResourcePlugin } from "../../../../../@types/sd/resourceplugin";
import ViewSpec from "../../../../../components/theming/ViewSpec/ViewSpec";
import useApplyResourcePluginSpec from "./useApplyResourcePluginSpec";

type ResourcePluginSpecProps = {
  resourcePlugin: ResourcePlugin;
};

const ResourcePluginSpec: React.FC<ResourcePluginSpecProps> = ({
  resourcePlugin,
}) => {
  const yamlSpec = yaml.dump({
    name: resourcePlugin.name,
    spec: resourcePlugin.spec,
  });

  return <ViewSpec yaml={yamlSpec} />;
};

export default ResourcePluginSpec;
