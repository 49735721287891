import _ from "lodash";
import type { ParsedTTL } from "../../hooks/UseTTL";
import { parseTTL } from "../../hooks/UseTTL";
import type { ClusterDefault } from "../../@types/sd/cluster";

const hasTTLChanged = (
  defaultTTL: ClusterDefault | undefined,
  ttl: ParsedTTL
) => {
  if (!defaultTTL && ttl.enabled && ttl.duration) return true;

  if (!defaultTTL) return false;

  const parsedDefaultTTL = parseTTL(
    {
      duration: defaultTTL.value.duration,
      offsetFrom: defaultTTL.value.offsetFrom,
    },
    undefined
  );

  return !_.isEqual(parsedDefaultTTL, ttl);
};
export const hasRouteGroupTTLChanged = (
  defaultRouteGroupTTL: ClusterDefault | undefined,
  ttl: ParsedTTL
) => hasTTLChanged(defaultRouteGroupTTL, ttl);

export const hasSandboxTTLChanged = (
  defaultSandboxTTL: ClusterDefault | undefined,
  ttl: ParsedTTL
) => hasTTLChanged(defaultSandboxTTL, ttl);

