import React from "react";
import { Intent, OverlayToaster } from "@blueprintjs/core";
import yaml from "js-yaml";
import { useNavigate } from "react-router-dom";
import type { ApiError } from "../../../../../hooks/UseApi";
import type { RunnerGroup } from "../../../../../@types/sd/runnergroup";
import { useApplyRunnerGroupApi } from "../../../../../api/RunnerGroupApi";
import SdButton from "../../../../../components/theming/SdButton";
import { RunnerGroupEditorPageApplyRunnerGroup } from "../../../../../Constants";

interface Props {
  spec?: string;
}

const toaster = OverlayToaster.create();

export const useApplyRunnerGroupSpec = (
  isNewRoutegroup: boolean,
  onApplied: (runnerGroup: RunnerGroup) => void
) => {
  const [applyRunnerGroup, applyRunnerGroupApi] = useApplyRunnerGroupApi(
    (runnerGroup) => {
      toaster.show({
        message: isNewRoutegroup
          ? "Job Runner Group created!"
          : "Job Runner Group updated!",
        intent: Intent.SUCCESS,
      });

      onApplied(runnerGroup);
    },
    (error: ApiError) => {
      toaster.show({
        message: error.response.data.error,
        intent: Intent.DANGER,
      });
    }
  );

  const handleApplyRunnerGroup = (spec: string) => {
    try {
      const req = yaml.load(spec!) as RunnerGroup;
      applyRunnerGroup(req);
    } catch (e) {
      toaster.show({
        message: `error parsing yaml`,
        intent: Intent.DANGER,
      });
    }
  };

  return {
    trigger: (spec: string) => {
      handleApplyRunnerGroup(spec);
    },
    isLoading: applyRunnerGroupApi.isLoading,
  };
};

const ApplyRunnerGroupAction: React.FC<Props> = ({ spec }) => {
  const navigate = useNavigate();

  const { trigger, isLoading } = useApplyRunnerGroupSpec(
    true,
    (runnerGroup) => {
      navigate(`/testing/job-runner-groups/${runnerGroup.name}`);
    }
  );

  return (
    <div>
      <SdButton
        eventName={RunnerGroupEditorPageApplyRunnerGroup}
        onClick={() => spec && trigger(spec)}
        disabled={!spec || isLoading}
        className="mt-3"
      >
        Apply
      </SdButton>
    </div>
  );
};

export default ApplyRunnerGroupAction;
