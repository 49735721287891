import React from "react";
import type { SandboxV2 } from "../../../../@types/sd/sandboxv2";
import { useSandboxSpecYAML } from "../../../../components/theming/ViewSpecDialog/hook";
import ViewSpec from "../../../../components/theming/ViewSpec/ViewSpec";
import { useSaveSandboxSpec } from "../../../CreateSandbox/CreateSandboxAction/CreateSandboxAction";

interface SandboxSpecProps {
  sandbox: SandboxV2;
}

const SandboxSpec: React.FC<SandboxSpecProps> = ({ sandbox }) => {
  const yamlSpec = useSandboxSpecYAML(sandbox);
  const { trigger, isLoading } = useSaveSandboxSpec(false, () => {});

  return (
    <ViewSpec
      yaml={yamlSpec}
      isEditable
      resource="sandbox"
      onChange={trigger}
      loading={isLoading}
    />
  );
};

export default SandboxSpec;
