import yaml from "js-yaml";
import React from "react";
import type { RunnerGroup } from "../../../../../../@types/sd/runnergroup";
import ViewSpec from "../../../../../../components/theming/ViewSpec/ViewSpec";
import { useApplyRunnerGroupSpec } from "../../../RunnerGroupEditor/ApplyRunnerGroupAction/ApplyRunnerGroupAction";

type Props = {
  runnerGroup: RunnerGroup;
};

const Specification: React.FC<Props> = ({ runnerGroup }) => {
  const yamlSpec = yaml.dump({
    name: runnerGroup.name,
    spec: runnerGroup.spec,
  });

  const { trigger, isLoading } = useApplyRunnerGroupSpec(false, () => {});

  return (
    <ViewSpec
      yaml={yamlSpec}
      isEditable
      onChange={trigger}
      loading={isLoading}
      resource="job-runner-group"
    />
  );
};

export default Specification;
