import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import type { FilterOptions } from "./Filter";

type FilterOptionWithValue<T> = FilterOptions<T> & { value: string };

type FilteredViewContextType<T> = {
  rawData: T[];
  filteredData: T[];
  filters: FilterOptions<T>[];
  filterAll: (filtered?: FilterOptionWithValue<T>[]) => void;
  setFilters: (filters: FilterOptions<T>[]) => void;
  updateFilteredData: (newFilteredData: T[]) => void;
  usedFilters: FilterOptionWithValue<T>[];
  setUsedFilters: (f: FilterOptionWithValue<T>[]) => void;
};

const FilteredViewContext = createContext<FilteredViewContextType<any> | null>(
  null
);

export const useFilteredViewContext = <T,>() => {
  const context = useContext(FilteredViewContext);
  if (!context) {
    throw new Error(
      "useFilteredViewContext must be used within a FilteredViewProvider"
    );
  }
  return context as FilteredViewContextType<T>;
};

type FilteredViewProviderProps<T> = {
  rawData: T[];
  children: React.ReactNode;
};

export const FilteredViewProvider = <T,>({
  rawData: inputRawData,
  children,
}: FilteredViewProviderProps<T>) => {
  const [filteredData, setFilteredData] = useState(inputRawData);
  const [rawData, setRawData] = useState(inputRawData);
  const [filters, setFilters] = useState<FilterOptions<T>[]>([]);
  const [usedFilters, setUsedFilters] = useState<FilterOptionWithValue<T>[]>(
    []
  );

  const updateFilteredData = (newFilteredData: T[]) => {
    setFilteredData(newFilteredData);
  };

  const filterAll = (filtersTarget = usedFilters) => {
    const filtered = rawData.filter((row) =>
      filtersTarget.every((f) => f.filter(row, f.value))
    );

    updateFilteredData(filtered);
  };

  useEffect(() => {
    setRawData(inputRawData);
  }, [inputRawData]);

  useEffect(() => {
    filterAll(usedFilters);
  }, [rawData, usedFilters]);

  const value: FilteredViewContextType<T> = useMemo(
    () => ({
      rawData,
      filters,
      filteredData,
      usedFilters,
      filterAll,
      updateFilteredData,
      setFilters,
      setUsedFilters,
    }),
    [filteredData, rawData]
  );

  return (
    <FilteredViewContext.Provider value={value}>
      {children}
    </FilteredViewContext.Provider>
  );
};
