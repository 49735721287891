import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import type { FilterOptions } from "../Filter";
import Filter from "../Filter";
import style from "./Filters.module.css";
import Tag from "../../theming/Tag";
import { useFilteredViewContext } from "../FilteredViewContext";

const MINIMUM_OPTIONS_SHOW_SEARCH = 6;

type FiltersProps<T> = {
  filters: FilterOptions<T>[];
};

const Filters = <T,>({ filters }: FiltersProps<T>) => {
  const { setFilters, filterAll, usedFilters, setUsedFilters } =
    useFilteredViewContext<T>();

  useEffect(() => {
    setFilters(filters);
  }, [filters]);

  const handleFilterChange =
    (filter: FilterOptions<T>) => (selectedOption: string | undefined) => {
      const filtered = usedFilters.filter((f) => f.label !== filter.label);

      if (selectedOption) {
        filtered.push({ ...filter, value: selectedOption });
      }

      setUsedFilters(filtered);

      filterAll(filtered);
    };

  const selectedValuesFilters = useMemo(
    () =>
      usedFilters.reduce(
        (parsedFilters, currentFilter) => ({
          ...parsedFilters,
          [currentFilter.label]: currentFilter.value,
        }),
        {}
      ),
    [usedFilters]
  );

  return (
    <>
      <p className={style.title}>Filters</p>
      <div className={style.container}>
        <div
          className={classNames({
            [style.filter_tags]: usedFilters.length !== 0,
          })}
        >
          {usedFilters
            .sort((fa, fb) => fb.label.localeCompare(fa.label))
            .map((filter) => (
              <Tag
                key={filter.label}
                text={
                  <>
                    {filter.label}: <span>{filter.value}</span>
                  </>
                }
                onClose={() => handleFilterChange(filter)(undefined)}
                position="left"
              />
            ))}
        </div>

        {filters.map((f) => (
          <Filter
            onChange={handleFilterChange(f)}
            key={f.label}
            {...f}
            selectedValue={selectedValuesFilters[f.label]}
            showSearch={f.options.length >= MINIMUM_OPTIONS_SHOW_SEARCH}
          />
        ))}
      </div>
    </>
  );
};

export default Filters;
